/* Start Header Css */
.authItems {
    display: inline-block;
    margin-right: 20px;
}

.userDetails {
    margin: 0 14px;
}

.userImg {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50px;
    border: 2px solid #fff;
    background: #ffff;
}

.authIcons {
    width: 30px;
    height: 30px;
   object-fit: contain; 
}

.authLinks {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}
.notification img{
    width: 33px;
    height: 33px;
}
/* .notification::after {
    position: absolute;
    content: '';
    width: 9px;
    height: 9px;
    display: block;
    background: #5CC13E;
    border-radius: 50px;
    top: -2px;
    right: 4px;
    border: 1px solid #3750A1;
} */

/* End Header Css */
/* Start Signup Css */
.continueField {
    border: 1px solid #6E6E6E;
    display: block;
    border-radius: 50px;
    position: relative;
    padding: 8px 2px;
    color: #4F4F4F;
    font-size: 18px;
    transition: all 0.3s;
    width: 100%;
}

.conitnueIcon {
    width: 34px;
    height: 34px;
    object-fit: contain;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translate(0%, -50%);
}

/* ENd Signup Css */
/* Start RegistrationForm Css */
.formContainer {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
}

.stepBtn {
    background: #5CC13E;
    font-size: 14px;
    border-radius: 8px;
    display: inline-block;
    color: #fff;
    padding: 7px 19px;
}

.rightContentArea {
    background: url('../images/registrUser.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    border-radius: 8px;
    padding: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.stepBoxMain {
    background: url('../images/stepThree.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.stepBoxFourMain {
    background: url('../images/stepFour.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.stepBoxfiveMain {
    background: url('../images/stepFive.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.stepBoxsixMain {
    background: url('../images/member3.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* End RegistrationForm Css */
/* Start Verification Css */
.verification .detailsContainer {
    padding: 4% 27%;
}

.input-groups {
    margin: 30px 0;
}

/* .input_digits_ {
    width: 53px;
    height: 53px;
    background-color: rgb(52 52 52 / 10%);
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #5E5F5E;
    margin: 0 5px;
    border-radius: 5px;
} */
.css-tccdxk {
    justify-content: center;
    gap: 0 !important;
}

.MuiOutlinedInput-input {
    padding: 0 !important;
    width: 53px !important;
    height: 53px !important;
    background-color: rgb(52 52 52 / 10%) !important;
    border: 0 !important;
    line-height: 50px !important;
    text-align: center !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #5E5F5E !important;
    margin: 0 5px !important;
    border-radius: 5px;
}

.MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

/* End Verification Css */

.showinfoText li {
    list-style: disc;
    margin-left: 14px;
}

.forgotPassword .primaryBtn{
    padding: 12px 44px !important;
}