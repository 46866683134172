/*Start Common Css */
.cursor{
    cursor: pointer;
}
.dFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.heading58{
    font-size: 58px;
    font-weight: 400;
    color:#fff;
}
.headingBlue58{
    font-size: 58px;
    font-weight: 500;
    color:#405cb1;
}
.headingGreen58 {
    font-size: 58px;
    font-weight: 700;
    color: #ffffff;
}
.heading44 {
    font-size: 44px;
    font-weight: 400;
    color: #2f4ca6;
}
.heading42 {
    font-size: 42px;
    font-weight: 800;
    color: #2f4ca6;
}
.headingGreen30 {
    font-size: 30px;
    font-weight: 700;
    color: #24469a;
}
.heading36{
    font-size: 35px;
    font-weight: 500;
    color:#1e3785;
}
.headingGreen36{
    font-size: 35px;
    font-weight: 700;
    color:#5cc13e;
}
.headingGreen16{
    font-size: 16px;
    font-weight: 500;
    color:#5cc13e;
}
.heading30{
    font-size: 29px;
    font-weight: 500;
    color:#fff;
}
.riskFreeTrialHeading{
    color: #405cb1;
    font-size: 28px;
    font-weight: 500;
}
.headingBlue30{
    font-size: 29px;
    font-weight: 400;
    color:#26439d;
}
.headingBlack30{
    font-size: 29px;
    font-weight: 400;
    color:#353535;
}
.heading27{
    font-size: 27px;
    color:#2f4ca6;
    font-weight: 400;
}
.heading21{
    font-size: 21px;
    color:#2f4ca6;
    font-weight: 500;
}
.headingBlack21{
    font-size: 21px;
    color:#2c2e31;
    font-weight: 500;
}
.heading17{
    font-size: 17px;
    color:#2f4ca6;
    font-weight: 500;
}
.txt26{
   color: #fff;
   font-size: 26px;
   font-weight: 600; 
}
.txtBlack26{
    color: #232323;
    font-size: 26px;
    font-weight: 600; 
 }
.txt24{
    color: #fff;
    font-size: 22px;
    font-weight: 600; 
    margin-bottom: 0;
 }
 .txt23{
    color: #3c3c3c;
    font-size: 21px;
    font-weight: 400; 
    margin-bottom: 0;
 }
 .txt20{
    color: #3c3c3c;
    font-size: 20px;
    font-weight: 400; 
    margin-bottom: 0;
 }
 .txtGray20{
    color:#232323;
    font-size: 20px;
    font-weight: 400; 
    margin-bottom: 0;
 }
.txt19{
    color: #fff;
    font-size: 18px;
    font-weight: 500; 
 }
 .txt18{
    color:#fff;
    font-size: 17px;
    font-weight: 500;
 }
 .txtBlack17{
    color:#313131;
    font-size: 17px;
 }
 .txtBlue17{
    color:#2f4ca6;
    font-size: 17px;
    font-weight: 600;
 }
 .txtGreen18{
    color:#5cc13e;
    font-size: 17px;
    font-weight: 700;
 }
 .txt14{
    color:#fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
 }
 .txt16{
    color:#404040;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
 }
 .txtBlue15{
    color:#26439d;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
 }
 .txt15{
    color:#000000;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
 }
 .fw-500{
    font-weight: 500 !important;
 }
 .fw-800{
    font-weight: 800 !important;
 }
 .fw-700{
    font-weight: 700 !important;
 }
 .fw-600{
    font-weight: 600 !important;
 }
 .cursorPointer{
    cursor: pointer;
 }
 .saveSearch{
    font-weight: 600;
    font-size: 13px;
    padding:10px 20px !important;
 }
/* End Common Css */
/* Start Certificat Page Css */
.certificateBanner {
    background: url('../images/bannerBg2.webp');
    background-size: cover;
    background-position: bottom;
    overflow: hidden;
    width: 100%;
    height: calc(80vh - 140px);
    display: flex;
    align-items: end;
}
.overviewCon li{
   display: inline-block;
   margin-right: 25px;
}
.overviewCon li a{
    color: #2b2b2b;
    font-size: 19px;
    font-weight: 600;
}
.overviewCon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.getBtn {
    background-color: #5cc13e;
    padding: 15px 17px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.getBtn img{
    width: 35px;
    margin-left: 15px;
}
.diveersitySection {
    padding: 74px 0;
    background-color: #f7f7f7;
}
.diveersitySection .headingGreen30{
    font-size: 22px;
}
.diveersitySection .txt25{
    font-size: 20px;
}
.diveersityLeft .Certificationstxt20{
    font-size: 16px !important;
}
.overviewLinks {
    background-color: #fff;
}
.supplierSection {
    background-color: #ffffff;
    padding: 100px 0;
}
.supplierSection .supplierRight .headingGreen30{
font-size: 22px;
}
.supplierSection .supplierRight .textHeading25{
    font-size: 20px;
}
.supplierSection .supplierRight .txt20{
font-size: 16px;
}
.supplierList {
    color: #00000091;
    padding-left: 45px;
    font-size: 7px;
}
.supplierList li{
    list-style: disc;
}
.diversitySection {
    background-color: #ffffff;
    padding: 120px 0 100px 0;
}
.getStaredBtn {
    background-color: #54b637;
    padding: 11px 33px;
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
}
.diversitySection .supplierList {
    padding-left: 0px;
}
.diversityCon {
    background: #ffffff;
    padding: 54px 0 37px 44px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 5px;
    border-radius: 20px;
    position: relative;
}
.diversityCon .headingGreen30{
    color:#5cc13e !important;
}
.diversityRenewal {
    display: flex;
    align-items: center;
    margin: 65px 0 60px 0;
}
.diversityRenewal img{
    margin-right: 24px;
}
.diversityLeft .diversityRenLeft{
    margin-right: 19px;
    padding-right: 19px;
    border-right: 2px solid #c7c7c7;
}
.diversityLeft .txt24{
    color: #3c3c3c;
    font-size: 18px;
    margin-bottom: 0;
}
.certificateFaqSec{
    background-color: #ffffff !important;
}
.certificateFaq .containerBox{
    background: transparent !important;
    padding: 0;
}
.certificateFaq .faqcontentBox_{
    padding: 0;
}
.certificateFaq .faq-row-wrapper{
    background: transparent !important;
    padding:0;
}
.certificateFaq .faq-row{
    border-bottom: none !important;
    margin-bottom: 25px;
}
.certificateFaq .row-title-text {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
}
.certificateFaq .row-content-text {
    font-size: 16px !important;
    color: #343434;
    line-height: 28px;
    padding-left: 31px !important;
    padding-top: 27px !important;
    padding-bottom: 27px !important;
}

.certificateFaq .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    padding: 0px 0 0px !important;
}
.certificateFaq .styles_row-content__QOGZd{
    padding: 0px!important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
}
.certificateFaq .row-title{
    background: #1e3785;
    padding: 9px 16px !important;
    border-radius: 10px;
    color: #fff;
}
.certificateFaq .icon-wrapper{
    top:auto !important;
}
.certificateFaq .faqcontentBox_ .row-title.closed {
    background: #323643;
    padding: 9px 16px !important;
    border-radius: 10px;
    color: #fff;
}
.certificateFaq .styles_icon-wrapper__2cftw {
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.listDotted li {
    list-style: disc;
}
.listDotted{
    padding-left: 85px;
}
.listDottedSecond{
    padding-left: 105px;
}
.listDottedSecond {
    padding-left: 185px;
}
.listDottedSecond li{
    list-style: disc;
}
.listDotted2 li {
    list-style: disc;
    font-size: 17px;
    color: #000000;
}
.listDotted2 i{
    color: #26439d !important;
    font-weight: 600;
}
.listMargin {
    margin-left: 78px;
}
.certificateFaq{
    padding:100px 0 110px 0;
    background: #f7f7f7;
}
.white{
    background-color: #fff !important;
}
.whiteDark{
    background-color: #f7f7f7 !important;
}
#buyers,#membership,#suppliers,#opportunities,#community {
    scroll-margin-top: 76px;
}
#thefounder,#mission,#leadership,#press,#soundbytes,#spotlight,#Sundayfunday,#Knowledgeexchange,#Mainstage,#Paneldiscussion,#Virtualtrade,#diversityCertificate,#whyItMatter,#faq,#requirement,#pricing{
    scroll-margin-top: 76px;
}
#press{
    scroll-margin-top: 156px;
}
#soundbytes{
    scroll-margin-top: 110px;
}
.diversityRightImg {
    position: absolute;
    right: 0;
    top: -84px;
}
.resourceFaqList li{
    list-style: disc !important;
}
.faqInnerList{
    display: flex;
    align-items: center;
    
}
/* End Certificat Page Css */
/* Start Post Button Css */
.postBtn {
    position: fixed;
    top: 88px;
    left: 45%;
    transform: translate(-43%, 2px);
    color: #fff;
    border-radius: 26px;
    border-color: cadetblue;
    box-shadow: 0px 0px 4px #0d6efd;
    background: #1c3276;
    transition: transform .2s;
    padding: 6px 14px;
    font-weight: 600;
    font-size: 13px;
    z-index: 1;
    animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  }
/* End Post Button Css */
/* Start Certificate Application Page */
.certificateApp{
    padding:58px 0 71px;
    /* background-color: #fff; */
}
.certificateAppLeft{
    padding:38px 58px 29px 32px;
}
.certificationCon{
    background-color: #fff;
}
.certificateAppLeft .txt18{
    color: #3c3c3c;
}
.certificateAppBtn {
    text-align: center;
    width: 100%;
    border-radius: 6px;
    padding: 7px 0;
    font-size: 20px;
    font-weight: 500;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 15px 2px !important;
}
.certificateAppBtn.active {
    background-color: #1c3b9bf5;
    color: #fff;
}
.certificateAppBtnCon {
    margin: 45px 0 28px;
}
.certificationCon .primaryBtn{
    font-size: 18px;
    padding:12px 50px !important;
    margin-bottom: 34px;
}
.dashboardLink{
    color: #284792;
}
.certificateAppRight{
    height: 100%;
    position: relative;
    /* border-radius: 8px; */
    padding: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: url('../images/certificationApp.svg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.certificateAppLeft .showinfoText{
    width: 246px;
    top: 47px;
    left: 60px;
}
.certificateAppLeft .showinfoText h2{
    font-size: 15px;
}
.faqDocuments{
    font-weight: 600;
    color: #000;
}
.faqText{
    color: #000;
}
.anyQueHeading{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin-top: 60px;
}
/* End Certificate Application Page */
/* Start Register Step Css */
.registrationStep{
    padding:55px 0;
}
.plusFields {
    background-color: #71706f;
    border-radius: 14px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-top: 6px;
    cursor: pointer;
    margin-bottom: 11px;
}
.plusFields i{
    color: #fff;
    font-size: 12px;
}
.docUpload{
    text-align: center;
}
.docUpload img{
    width: 69px;
    height: 78px;
    border-radius: 15px;
}
.viewBtn{
    background-color: #707070;
    border-radius: 20px;
    border: none;
    box-shadow: none;
    font-size: 10px;
    color: #fff;
    padding: 3px 20px;
}
.stepOne{
    background: url('../images/step1.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.stepTwo{
    background: url('../images/step2.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.stepThree{
    background: url('../images/step3.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.stepFour{
    background: url('../images/step4.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.stepFive{
    background: url('../images/step5.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.stepSix{
    background: url('../images/step6.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.stepSeven{
    background: url('../images/step7.webp') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.customTypeDate .react-datepicker-wrapper{
    width: 100%;
}
.customTypeDate .inputIcon{
    right: 12px;
}
/* End Register Step Css */
/* Start Manage User Tab Setting Page */
.manageUser{
    padding:18px 22px;
    background-color: #fff;
    height: 100%;
}
.userAvailable{
    display: flex;
    align-items: center;
    justify-content: end;
    margin:60px 0 55px 0;
}
.manageUserTable{
    width: 100%;
    min-width: 450px;
}
.manageUserTable thead{
    border-bottom: 1px solid rgb(199, 199, 199);
}
.manageUser .primaryBtn{
    padding: 10px 20px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}
.manageUserTable th{
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 14px;
}
.manageUserTable td{
    font-size: 13px;
    font-weight: 500;
    padding: 14px 0;
}
.pendingTxt{
    color: #c5c5c5;
}
.trashIcon{
    cursor: pointer;
}
/* End Manage User Tab Setting Page */
/* Start Privacy Tab  Settings*/
.PrivacySettings .subsmallBtnblack_{
    background-color: transparent;
    color: #1c3276;
    font-weight: 600;
    font-size: 15px;
}
.PrivacySettings .dropdown-menu .dropdown-item{
    font-size: 12px !important;
}
.deactivateDeleteBtn{
    display: flex;
    align-items: center;
}
.deactivateDeleteBtn .subsmallBtngreen_{
    font-size: 13px;
    padding: 10px 20px !important;
    background-color: #ED5E68;
    font-weight: 600;
    border-radius: 8px;
}
.privacySetting .headingTitleLight_{
    font-size: 14px;
}
/* End Privacy Tab  Settings*/
/* Start Plans Modal Css */
#plans{
    max-width: 650px;
}
/* End Plans Modal Css */
/* Start Resourse Page Css */
.letterSpace{
    letter-spacing: 1px;
}
.bussinessResourcePage .innerSubtext.txt20{
    color:#282828;
    font-size: 20px!important;
}
.bussinessResourcePage .innerSubtext{
    color:#262626;
    font-size: 16px!important;
}
.bussinessResourcePage .landingHead_ .innerSubtext{
    font-size: 24px !important;
    color: #232323 !important;
}
.diverseCertification .secImg{
    border-radius: 13px;
}
.diverseCertification .goIcon {
    right: 19px;
    bottom: 13px;
}
.bussinessDevelopmentSection{
    padding:106px 0;
    background-color: #ffffff;
}
.sundayFundaySec{
    background-color: #f7f7f7 !important;
}
.suggestionBox{
background-color: #ffffff !important;
}
.pannelDisscussionTxt{
    font-size: 18px;
    color:#405cb1 !important;
    margin-top:26px;
}
.memberSpotlightList{
    /* margin-left: 20px !important; */
}
.memberSpotlightList li{
    /* list-style: disc !important; */
}
.resourceSunday .goIcon{
    left: 20px;
    bottom: 20px;
    right: auto;
}
.virtualTrade{
    background-color: #f7f7f7;
}
.memberSpotLight .goIcon{
bottom: 20px;
right: 20px;
}
.virtualTrade .goIcon{
    bottom: 20px;
    right: 20px;
    }
/* End Resourse Page Css */
/* Start Review Respond Page */
.chatUserImg img{
    max-width: 140px !important;
}
/* End Review Respond Page */
/* Start Help Desk Page */
.helpDeskSend{
    padding:100px 0;
}
.helpDesk .homeBanner{
    background: url('./../images/bannerBg2.webp') no-repeat !important;
    width: 100% !important;
    background-size: cover !important;
    padding-bottom: 40px !important;
}
.recommended {
    padding: 90px 0 185px 0;
    background: #e8e8e8;
}
.recommended .searchBox{
    margin-bottom: 64px;
    max-width: 400px;
}
.recommended input::placeholder{
    color:#a5a5a5 !important;
    font-size: 18px !important;
}
.recommended .searchBox .rounded {
    padding: 7px 38px 7px 13px;
}
.recommended .searchBox .searchBtn {
    right: 6px !important;
    left: auto;
    top: 5px;
}
.recommended .searchBox .searchBtn img{
    width: 23px;
    height: 23px;
}
.recomendedCard{
    background-color: #425fb9;
    height: 100%;
    min-height: 140px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recomendedCard h3{
    font-weight: 600;
    font-size: 22px;
    color: #fff;
    text-align: center;
}
.suggestedHeading{
    font-size: 22px;
    color:#292929;
    font-weight: 600;
    cursor:pointer;
}
.suggestedSubHeading{
    font-size: 17px;
    font-weight: 800;
    color:#425fb9;
    display: inline-block;
}
.suggestedDes{
    font-size: 16px;
    color:#292929;
    line-height: 25px;
}
.suggestedArticles {
    padding-top: 66px;
}
.helpDesk .bannerTxt{
    font-weight: 500;
}
.contractLength{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
}
.helpDesk a{
    color: #343434 !important;
}
.helpDesk .homeBanner{
    padding: 0px !important;
}
.helpDesk .bannerHead{
    font-size: 45px;
    padding-bottom: 20px;
    color: #405cb1 !important;
}
.helpDesk .aboutContainer{
    padding:161px 0px 44px 0  !important;
}
.helpDesk .bannerTxt{
    font-size: 24px !important;
    color: #232323 !important;
}
/* End Help Desk Page */
/* Start Responded Application Modal */
.respondAppHeader {
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.respondAppMain{
    display: flex;
    /* align-items: center; */
}
/* #responseModal .modal-header{
    display: none;
} */
/* #applicationModal .modal-header{
    display: none;
} */
#responseModal {
    max-width: 691px !important;
}
#applicationModal {
    max-width: 691px !important;
}
#responseModal .modal-body{
    padding:29px 18px 22px;
}
#responseModal .responseCompanyName{
font-size: 37px;
}
#applicationModal .modal-body{
    padding:29px 18px 22px;
}
.respondAppHeading h1{
    font-size: 30px;
    font-weight: 700;
    color: #1e3785;
}
.respondAppHeading p{
    color:#1b1b1b;
    font-size: 19px;
    font-weight: 500;
}
#responseModal .chatUserImg img{
    width: 145px;
    height: 117px;
    border-radius: 5px;
    border:1px solid #a3a3a3;
    padding:4px;
    object-fit:cover;
}
#applicationModal .chatUserImg img{
    width: 145px;
    height: 117px;
    border-radius: 5px;
    border:1px solid #a3a3a3;
    padding:4px;
    object-fit: cover;
}
.respondAppMainCompany h1{
    color:#737373;
    font-size: 19px;
}
.respondAppMainCompany p{
    color: #3d58ab;
    font-size: 37px;
    font-weight: 600;
}
.respondAppMainCompany .responseCompanyName{
    color:#3d58ab;
    font-size: 18px;
    font-weight: 600;
}
#responseModal .modal-content{
    border-radius: 12px !important;
}
#applicationModal .modal-content{
    border-radius: 12px !important;
}
.responseLocation{
    color:#737373 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}
#responseModal .sendBtn{
    background-color: #5cc13e;
    font-size: 16px;
    font-weight: 600;
    padding:10px 20px;
    border-radius: 5px;
    color:#fff;
}
#applicationModal .sendBtn{
    background-color: #5cc13e;
    font-size: 16px;
    font-weight: 600;
    padding:10px 20px;
    border-radius: 5px;
    color:#fff;
}
.respondAppInfo h4{
    font-size: 18px;
    font-weight: 500;
    color:#3d58ab;
}
.sendTime{
    color: #1B1B1B;
    font-weight: 500;
    font-size: 18px;
}
#applicationModal .recentlyBtn{
    padding: 12px 21px;
}
.sendBtn.deActiveBtn{
    background-color: rgb(59,59,59) !important;
}
/* End Responded Application Modal */
.diversityReadMore{
    font-size: 14px;
    font-weight: 600;
    color: #2F4894;
    cursor: pointer;
}
.disabled__{
    background-color: gray !important;
}
/* Start Post Modal Css */
#addNewPost{
    max-width: 621px;
}
#addNewPost .headingSmall_{
    color: #151515;
    font-weight: 700;
    font-size: 20px;
}
#addNewPost .modal-header{
    border-bottom: none;
}
#addNewPost .hrRow{
    background-color: #c7c7c7;
    height: 1px;
    width: 100%;
}
#addNewPost .modal-body{
    padding-top: 0;
}
.addPostImg{
    width: 62px;
    height: 62px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 15px;
    border: 1px solid #c2c2c2;
    padding: 2px;
}
#addNewPost .addPostTitle{
    font-size: 17px;
    font-weight: 600;
    color:#343434;
}
#addNewPost .addPostSub{
    font-size: 12px;
    color:#343434;
}
#addNewPost textarea{
    border: none !important;
    padding-left: 0;
    padding-top: 0;
}
#addNewPost textarea::placeholder{
    font-size: 17px;
    color:#343434;
}
#addNewPost .uploadImgeboxNew{
    border: 3px dotted #343434a8 !important;
}
#addNewPost .modal-header .btn-close{
    display: none;
}
#addNewPost .common_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#addNewPost .modalHeader_ {
    width: 100%;
}
.postBtn__{
    background-color: #49a82e;
    font-size: 18px;
    color:#fff;
    padding:16px 0;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
}
.addPostCon{
    display: flex;
    align-items: center;
}
.multiselect__ .chip{
    border-radius: 10px !important;
    padding: 8px 10px !important;
}
.postImg__ {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #c3c3c3;
    padding: 1px;
}
.startPost{
    border-radius: 35px!important;
    padding-left: 1rem!important;
    height: 50px;
    padding-top: 14px !important;
}
.uploadProfile__ i{
    margin-left: 0 !important;
}
.newAccountDash .postUser{
    border-radius: 10px !important;
}
.newAccountDash .posttedUser{
    border-radius: 10px !important;
}
.newAccountDash .msgImg{
    border-radius: 50% !important;
}
.newChatReplyIcon{
    width: 25px;
    margin-left: 22px;
    z-index: 1;
    position: relative;
    margin-top: 15px;
}
.newCommentReply{
    display: flex;
    align-items: center;
}
.newCommentReply .replyPerson{
    font-size: 16px;
    color:#343434;
    font-weight: 600;
}
.newCommentReply .replyTime{
    font-size: 12px;
    color:#2f4894;
    font-weight: 500;
}
.newCommentReply .chatPosttedUser{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
    border:1px solid #c3c3c3;
    padding:1px;
}
.newCommentReply .comments{
    padding:15px;
}
.opportunitiesListSec{
    background-color: #fff;
}
.newAboutFounder{
    background-color: #fff !important;
    padding:7rem 0;
}
.premiumBtn{
    font-size: 19px;
    font-weight: 500;
    color: #fff;
    background: #439f28;
    border-radius: 5px;
    padding: 8px 12px;
}
.knowledgeInnerRight{
    background: #fff;
    padding-left: 37px;
    border-radius: 0 20px 20px 0;
}
.bussinessImgs{
    border-radius: 20px 0px 0px 20px;

}
.knowledgeExchangeReverse .knowledgeInnerRight{
    background: #fff;
    padding-left: 37px;
}
.knowledgeExchangeReverse .bussinessImgs{
    border-radius: 0px 20px 20px 0px;
}
.knowledgeExchangeInner{
    background: #fff;
    border-radius: 20px;
}
.diversityCertificationSec{
    background-color: #fff;
    padding:90px 0;
}
.diversityCertificationSec .bussinessImgs{
    border-radius: 20px;
}
.startPostCon{
    background: #fff;
    padding: 20px 19px;
    border-radius: 8px;
}
.about15{
    font-size: 15px;
}
.connectedBtn{
    background-color: #4c4c4cab !important;
    background: #FFFFFF !important;
    border: 1px solid #BFBFBF;
    font-size: 13px;
    font-weight: 500;
    color: #131313ba;
}
.opportunityDownload{
    font-size: 22px;
    color: #4d6ac4;
}
.newChatMessage{
    display: flex;
    align-items: center;
}
.newChatMessage{
    display: flex;
    align-items: center;
}
.newPostDetails .innerSubtextSmall{
    color: #344D99;
    font-size: 17px;
}
.newPostDetails .timeingDetails{
    color: gray;
}
.messageDetailRight .discription_{
    color:#000000;
}
.newMotionImg{
    width: 100%;
    height: 150px;
    object-fit: cover;
    max-width: 150px;
    text-align: center;
    margin: 0 auto;
    border-radius: 8px;
    border: 1px solid #c3c3c3;
    padding: 1px;
}
.reviewImage .imgproduct_{
    min-height: 110px !important;
}
.replyChatMsg{
    background: #c3c3c370;
    padding: 10px;
    border-radius: 8px;
    margin-right: 5px;
}
.chatMsgImg {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    border-radius: 8px;
    object-fit: cover;
}
.featurePostCon {
    /* max-height: 500px; */
    height: 100%;
    /* overflow: auto; */
    padding-right: 8px;
}
.featurePostCon::-webkit-scrollbar{
    width: 0;
}
.sendFile{
    width: 70px;
    height: 70px;
}
.sendVideo{
    width: 70px;
    height: 70px;
}
.newHelpDesk .searchBtn{
    position: absolute;
    right: 5px;
    top: 6px;
    left: auto;
}
.newHelpDesk .searchBtn img{
    width: 20px;
    height: 20px;
}
/* Start Payment Modal */
#paymentCard .modal-header h1{
    font-size: 21px;
}
.card_input.StripeElement.StripeElement--empty,
.card_input.StripeElement.StripeElement--complete,
.card_input.StripeElement.StripeElement--focus,
.card_input.StripeElement.StripeElement--invalid,
.card_inputbox.StripeElement.StripeElement--empty,
.card_inputbox.StripeElement.StripeElement--focus,
.card_inputbox.StripeElement.StripeElement--invalid,
.card_inputbox.StripeElement.StripeElement--complete
{
    border: 1px solid #9595954a;
    padding: 10px;
    border-radius: 5px;
}

#paymentCard .primaryBtn{
    font-size: 16px !important;
    padding: 9px 14px !important;
}
#payment-card {
    background: #0F0F0F;
    border-radius: 20px;
}
.manageCardBody {
    padding: 22px !important;
}

.managePaymentNo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expiryOn {
    color: #999999;
    font-size: 10px;
}

.expiryDate {
    color: #fff;
    font-size: 12px;
}

.managePaymentName {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.masterCardlist li:first-child {
    border-radius: 50%;
    background: #EB001B;
    height: 45px;
    width: 45px;
}

.masterCardlist li:last-child {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin-left: -24px;
    background: rgb(0,153,223,0.7);
}

.masterCardlist li {
    display: inline-block;
}

.masterCardHeading {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
}
.cardSelected{
    border: 1px solid #012c5a !important;
    /* border-radius: 24px; */
    padding: 2px;
}
.addCardBtn {
    background: #3B57A9;
    padding: 8px 12px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    display: block;
}

/* Start New Help Desk Que */
.lastUpdateQue{
    color: #b1b1b1;
    font-size: 17px;
}
.helpFullCon{
    position: relative;
    margin-top: 60px;
    border: 2px solid #b1b1b1;
    background-color: #fff;
}
.helpFullCon input{
    width: 73%;
    padding: 20px;
    border: none;
}
.helpFullCon input::placeholder{
   font-size: 22px;
   color:#616161;
   font-weight: 600;
}
.helpFullCon button{
    position: absolute;
    right: 0;
    font-size: 20px;
    color: #fff;
    background: #425fb9;
    padding: 11px 30px;
    border-radius: 10px;
    top: 50%;
    transform: translate(-5%, -50%);
    font-weight: 600;
}
.helpQueCanHelp{
    position: relative;
    margin-bottom: 30px;

}
.helpQueCanHelp input{
    width: 100%;
    padding: 11px;
    border: 2px solid #bfbfbf;
    border-radius: 5px;
    padding-right: 41px;
}

.helpQueCanHelp img{
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translate(-4%, -50%);
}
.recommendedList{

}
.recommendedItem{
    font-size: 22px;
    color: #fff;
    background-color: #606060;
    margin-bottom: 6px;
    padding:13px;
    border-radius: 8px;
    font-weight: 500;
}
.recommendedItem.active{
    background: #425fb9;
}
.recommendedListl li{
    background: #606060;
    padding: 15px;
    border-radius: 10px;
}
.recommendedListl li span{
    font-size: 22px;
    color: #fff;
    font-weight: 500;
}
/* End New Help Desk Que */
/*Start new payment Card */
.paymentCard{
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 16px;
}
.paymentCardHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cardDetailsOuter{
    display: flex;
}
.paymentCardHead h6{
    color:#01346a;
    font-size: 15px;
    font-weight: 600;
}
.cardDetails p{
    font-size: 14px;
    color: #1f1f1f;
}
.addNewPaymentBtn{
    background-color: #012c5a;
    font-size: 15px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    padding:14px;
    width: 100%;
    text-transform:uppercase;
}
/*End new payment Card */

/* End Payment Modal */
/* Start free trial Css */
.freeTrialSec {
    background: #232323;
    padding: 15px 0;
    /* position: sticky;
    top: 0;
    z-index: 9; */
}
.freeTrialInner p{
    color: #fff;
    font-size: 18px;
}
.freeTrialLink{
    font-size: 19px;
    color: #2cb12f !important;
    text-decoration: underline;
    font-weight: 600;
}
.limitedTimeOffer{
    margin-top: 20px !important;
}
/* End free trial Css */
/* Start Mobile App Section */
.mobileAppInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.mobileAppLeft{
    margin-right: 40px;
}
.mobileAppRight .premiumBtn{
    background-color: #6e706d !important;
}
.mobileAppRight .heading_Blue {
    font-size: 32px;
}
.mobileAppRight .innerSubtext {
    font-size: 18px;
}
.mobileApp{
    padding-top:110px;
}
/* End Mobile App Section */
/* Start Crop Modal Css */
#cropImage .modal-body{
    height: 100%;
    /* min-height: 460px; */
    text-align: center;
}
#cropImage .primaryBtn{
    font-size: 13px !important;
    padding: 10px 14px !important;
}
.corpImage_{
    max-height: 446px !important;
    object-fit: contain !important;
    text-align: center!important;
    margin: 0 auto!important;
    width: 100%;
    /* min-height: 446px !important; */

}
/* End Crop Modal Css */
.enterPriceSub{
    color: #2c2e31;
    font-size: 15px;
}
.bgWhite{
    background-color: #fff !important;
}
.recommended .searchBox .inputClose  {
    position: absolute;
    right: 6px !important;
    left: auto;
    top: 5px;
}
.memberLoder{
    height: 75vh;
}
.smallBussinesPlan{
    display: flex;
    align-items: center;
    justify-content: center;
}
.smallBussinesPlan .supBottom{
    font-weight: 600;
    margin-top:0.5em !important;
    display: inline;
}
.readPlans{
    color: #464646;
    font-size: 13px;
    cursor: pointer;
}
.readPlans a{
    text-decoration: underline;
    color: #464646;
    font-size: 13px;
}
.promoBtn{
    font-size: 16px;
    color:#fff;
    background-color: #012c5a;
    padding:11px 19px;
    border-radius: 8px;
}
.promocodeOuter {
    position: relative;
}
.promoSearch {
    border-radius: 8px;
    outline: none;
    border: 1px solid #c3c3c3;
    padding: 10px 100px 10px 10px;
    width: 100%;
}
.promoBtn {
    position: absolute;
    top: 0;
    right: 1px;
}
.promoCodeForm .promoSearch{
    width: 100%;
    padding-top: 9px;
}
.promoCodeForm .promoBtn {
    position: absolute;
    top: 1.7px;
    right: 1px;
}
.activationPromo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #c3c3c3;
    padding:8px 10px;
    border-radius: 8px;
}
.promoCodeForm .promoSearch{
padding-right: 85px;
}
.inputTel{
    height: 48px !important;
    width: 100% !important;
}
.inputTel input{
    height: 48px !important;
    width: 100% !important;
}
.counter{
    display: inline-block;
    background: #3B57A9;
    padding: 4px;
    border-radius: 50%;
    font-size: 17px;
    width: 28px;
    height: 28px;
    color: #fff;
}
.newinputTel input{
    padding-left: 48px !important;
    height: 58px !important;

}
.react-tel-input{
    height: 58px !important;
}
.react-tel-input .flag-dropdown{
    height: 58px !important;
    border-color: #6E6E6E;
}
.enterPriceSignUp .react-tel-input .flag-dropdown{
    height: 48px !important;
    border-color: #d8d8d8;
}
.counter{
    display: inline-block;
    background: #3B57A9;
    padding: 4px;
    border-radius: 50%;
    font-size: 17px;
    width: 28px;
    height: 28px;
    color: #fff;
}
.customColLgThree{
    width: 22%;
}
.customColLgFive{
    width: 44.666%;
}
.developerAcc{
    color: #979595 !important;
    font-weight: 500;
    font-size: 13px;
}
.dashboardButton .newPlanBtn{
    background: #fff!important;
    color: #131313ba!important;
    font-weight: 500;
    border: 1px solid #BFBFBF;
    font-size: 13px;
}

/***Priyanka Css ***/

.profileBox_ {
    display: inline-block;
    text-align: left;
    width: 100%;
    padding: 0 15px;
}
.lighthead_ {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    line-height: 28px;
    color: #343434;
}
.bottomMenu_ .searchBox {
    display: block!important;
    max-width: initial;
    padding: 0 15px;
}
.bottomMenu_ .searchBox .rounded {
    height: 55px;
    border: 1px solid #898989;
    border-radius: 7px!important;
    padding: 13px;
    width: 100%;
}
.bottomMenu_ .searchBox .searchBtn {
    position: absolute;
    left: 19px;
    top: 17px;
}
.bottomMenu_ .nav-link {
    color: #131313!important;
}
.bottomMenu_ .searchBox .rounded::placeholder {
    color: #343434!important;
}

.settingLinks.active{
    color: #425fb9;
}
.newClass {
    overflow: hidden!important;
}
.newPostOpportunity .checkmark_ input[type="checkbox"]:checked+label::after {
    top: 7px;
}
.noDataFound{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.noDataFoundImg{
    width: 70px;
    margin-bottom: 25px;
}
.termsList{
    margin-left: 20px !important;
}
.termsList li{
    list-style:disc !important;
}
.bgGreyBtn {
    background-color: #6c747e;
    border-radius: 8px;
    color: #fff !important;
    font-size: 22px;
    border: none;
    padding: 12px 14px !important;
    /* display: block; */
    /* width: 100%; */
}
.beforeMobileMenu .navbar-expand-lg{
    width: 100%;
}
.mobileFooter{
    padding: 20px 0px 0;
    border-top: 1px solid #c3c3c3;
    margin: 15px 15px;
}
.bgBlue__{
    background-color: #405cb1 !important;
    border-radius: 5px;
    color: #fff !important;
    font-size: 15px;
    border: none;
    padding: 12px 20px !important;
    /* width: 223px; */
    font-weight: 500 !important;
}
.viewProfileBtn{
    background-color: #2F4894;
    padding: 11px 17px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    border-radius: 8px;
}
.sendAMessage {
    color:#BFBFBF !important;
    background: transparent !important;
    border: 1px solid #BFBFBF;
    font-size: 13px;
    font-weight: 500;
}
.designbtnRight .subsmallBtngreen_ {
    border-radius: 6px;
    padding: 10px 14px;
}
.newCompanyDetailsPro_{
    margin-top:-76px;
}
.profileAbout{
        position: relative;
        padding-top: 96px;
        margin-top: -76px;
}
.profileAbout img{
    width: 100px;
    height: 100px;
    border-radius: 8px;
    position: absolute;
    top: -25px;
    border: 1px solid #c3c3c3;
    padding: 2px;
}
.viewAllBtn___{
    background: #2F4894;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    font-size: 13px !important;
    font-weight: 500;
    min-width: 96px;
}
.activeOpportunities{
    font-size: 22px;
    color: #343434;
    margin: 0;
    font-weight: 600;
}
.inactiveOpportunities{
    padding: 6px 21px;
    border-radius: 5px;
    background: transparent !important;
    border: 1px solid #BFBFBF;
    font-size: 13px;
    font-weight: 500;
    color: #BFBFBF;
}
.chooseMemberShip__{
    display: flex;
    justify-content: space-between;
}

.toggleContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #BFBFBF !important;
    border-radius: 7px;
    background: #fff;
    font-weight: bold;
    color: #000;
    cursor: pointer;
    border-radius: 10px;
}
  .toggleContainer::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0%;
    border-radius: 9px;
    background: #2F4894;
    transition: all 0.3s;
  }
  .toggleCheckbox:checked + .toggleContainer::before {
     left: 50%;
  }
  .toggleContainer div {
    padding: 10px 7px;
    text-align: center;
    z-index: 1;
    font-size: 13px;
    font-weight: 500;
  }
  .toggleCheckbox {
    display: none;
  }
  .toggleCheckbox:checked + .toggleContainer div:first-child{
    color: #131313ba;
    transition: color 0.3s;
  }
  .toggleCheckbox:checked + .toggleContainer div:last-child{
    color: #fff;
    transition: color 0.3s;
    
  }
  .toggleCheckbox + .toggleContainer div:first-child{
    color: #fff;
    transition: color 0.3s;
    font-size: 13px;
    font-weight: 500;
  }
  .toggleCheckbox + .toggleContainer div:last-child{
    color: #131313ba;
    transition: color 0.3s;
  }

.cursor_text {
    cursor: text !important;
}

.border-white {
    border-color: white !important;
}
.userCompanyName{
    font-size: 17px;
    font-weight: 500 !important;
    color: #575757;
}
.errormsg {
    font-size: 12px;
    margin-top: 2px;
    padding: 6px 10px;
}
.billingBox .headingTitleSmall_{
font-size: 20px;
}
.awardTxt{
    font-style: italic;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin:0;
}
.getStartdHeader {
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 5px;
    color: #fff !important;
    font-size: 15px;
    border: none;
    padding: 12px 20px !important;
    font-weight: 500 !important;
}
.companyAclamation .userProfile {
    width: 150px;
    height: 150px;
}
.companyAclamation .innerSubtextSmall{
    font-size: 20px;
    color: #2F4894;
    margin-top:6px;
}
.companyAclamation .imageDiv img{
    border: 1px solid #CCCCCC !important;
    padding: 2px;
    border-radius: 8px;
}
.recivedAclamation{
    color:#3B56A7;
    margin-bottom: 10px;
}
.receiveAclamation .cardImg{
    border: 1px solid #c3c3c3;
    padding: 1px;
    border-radius: 8px;
}
.receiveAclamation{
    align-items: start;
}
.cropBtnModal{
    font-size: 13px !important;
    border-radius: 5px !important;
    padding:10px 20px !important;
    font-weight: 600 !important;
}
.newSearchBtns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}
.newCommonBtnOuter {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 285px;
    background: #fff;
    padding: 8px 8px;
    border-radius: 8px;
    border: none;
}
.newCommonBtnOuter1{
    min-width: 236px !important;
}
.css-t3ipsp-control{
    border: none !important;
    box-shadow: none !important;
}
.css-13cymwt-control {
    border: none !important;
}
.newCommonBtnOuter:focus{
    border: none;
}
.newCommonBtnOuter span{
    margin-right: 10px;
    font-size: 13px;
}
.newSelect{
    background-color: transparent;
    border: none;
    padding: 0;
    font-weight: 600;
    background-image: url(./../images/downDark.svg) !important;
    background-repeat: no-repeat;
    font-size: 13px !important;
    color: #3c3c3c !important;
    background-position: right 4px !important;
    max-width: 145px !important;
    background-size: 16px 16px !important;
    width: 100%;
    appearance: none;
}
.newLeftBtns{
    display: flex;
    /* flex-wrap: wrap;
    gap:10px; */
}
.newProfileBtn{
    min-width: 210px!important;
    margin-left: 10px;
}
.newOrderBtn{
    min-width: 224px!important;
}
.orderContainer{
    min-width: 150px !important;
}
.css-1dimb5e-singleValue {
    font-size: 13px;
    font-weight: 600;
}
.profileSelectContainer{
    min-width: 200px;
}
.viewAllMobile{
    display: none;
}
.appStore {
    background-color: #000;
    display: flex;
    align-items: center;
    padding:5px 10px;
    border-radius: 10px;
}
.playStore {
    background-color: #000;
    display: flex;
    align-items: center;
    padding:5px 10px;
    border-radius: 10px;
}
.appImage {
    margin-right: 15px;
}
.playStore img{
    width: 28px;
    height: 33px;
}
.appStore p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
.appStore h6 {
    color: #fff;
    font-weight: 600;
    font-size: 21px;
    line-height: 13px;
    margin-bottom: 5px;
}
.playStore p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
.playStore h6 {
    color: #fff;
    font-weight: 600;
    font-size: 21px;
    line-height: 13px;
    margin-bottom: 5px;
}
.trustedText{
    /* background-color: #fff; */
    margin-bottom: 0;
    font-size: 25px;
    color:#000;
    /* padding:30px 0; */
    /* text-align: center; */
}
.releaseSection{
    padding:30px 0;
    background-color: #fff;
}
.trustedCompaniesLogo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:20px;
}
/* Start Skelton Css */
.card {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .12);
}
.image {
    height: 200px;
}
.content {
    padding: 2rem 1rem;
}
.description {
    font-size: 1rem;
    line-height: 1.4rem;
}
.loading .image,
.loading h4,
.loading .description {
  background-color: #ededed;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
.loading h4 {
  min-height: 1.6rem;
  border-radius: 4px;
  animation-delay: .05s;
}
.loading .description {
  min-height: 4rem;
  border-radius: 4px;
  animation-delay: .06s;
}
.trustedCompaniesLogo .owl-carousel.owl-drag .owl-item {
    padding:0 30px;
}
/* End Skelton Css */