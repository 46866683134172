@media (min-width:1700px) {
    /* .rightImg {
        left: -37%;
        width: 65rem;
    } */

}

@media (min-width:1600px) {
    /* .rightImg {
        left: -29% !important;
        width: 65rem;
    } */

}

@media (max-width: 1500px) {
    /* .rightImg {
        left: -29% !important;
    } */
    .authMenus .userName {
        max-width: 4ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .inputSearchForm input::placeholder{
        font-size: 14px;
    }
    .nav-link{
        font-size: 13px;
    }
    
}

@media (min-width:1399px) {
    .bannerHead {
        font-size: 30px;
    }

    .aboutLady {
        width: 100%;
    }

    /* .rightImg {
        left: -36%;
    } */
}


@media (max-width:1400px) {
    .bussinessResourcePage .innerSubtext.txt20 br {
        display: none;
    }
    .aboutLady {
        width: 100%;
    }

    .memberPlansbox_ .memberHead_ {
        font-size: 25px;
    }

    .commonCartImg {
        display: block !important;
    }

    .commonCartImg img {
        height: 100%;
        max-height: 200px;
    }

    /* .authMenus .userName {
        max-width: 4ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } */
    .authMenus .userName {
        display: none;
    }

    .companyDetailsLinks {
        padding: 14px 18px;
        font-size: 15px;
    }
    .authMenus .authItems {
        margin-right: 8px;
    }
    .userDetails {
        margin: 0 3px;
    }
    .aboutMenusContainer .aboutLinks{
        font-size: 16px;
    }
}

@media (min-width: 1200px) {

    /* Start Hover Effect */
    .rolesContainer:hover {
        background: #aec1fb;
        border: 1px solid #aec1fb;
    }

    .continueField:hover {
        color: rgb(37, 87, 167);
        border-color: rgb(103, 146, 240);
        background-color: rgb(238, 241, 254);
    }

    .opptItems:hover {
        background: #1C3276;
        color: #fff !important;
    }

    .opptItems:hover .innerSubtext {
        color: #fff;
    }

    .authLinks:hover {
        color: #fff;
    }

}

@media (max-width: 1366px) {
    .growbusinessRight_ {
        width: 44%;
    }

    .memberMiddle {
        padding: 19px 13px;
    }

    #commentBox .dashboardComments {
        max-height: 410px;
    }
    .companyDetailsLeft .imgproduct_ {
        min-width: auto;
    }
   
}

@media (max-width: 1280px) {
    /* .rightImg {
        left: -28%;
        width: 52rem;
        bottom: -20%;
    } */
   
    .inputSearchForm .rounded {
        padding: 6px 22px 6px 28px;
    }
      

}

@media (max-width: 1199px) {
    .opportunityComtent_ {
        padding: 0 10px;
    }
    .contectUs_ .memberHomeImage_ {
        padding: 70px 0;
    }
    .businessSearch .selectListbox {
        justify-content: start;
    }
    .businessSearch .selectListbox .checkmark_.radioCheck_ {
        margin-left: 0;
        margin-right: 15px!important;
    }
    .businessSearch .secondaryBtnGrey {
        font-size: 12px;
    }

    .bussinessResourcePage .leftSec .innerSubtext {
        font-size: 18px !important;
    }

    .memberMiddle .memberHead_ {
        font-size: 22px;
    }

    .memberMiddle {
        padding: 19px 22px;
    }

    .featureListbox_ {
        min-height: 616px;
    }

    .nav-item {
        margin-right: 0;
    }

    .nav-link {
        letter-spacing: 0px;
        padding: 8px 14px;
    }

    
    .diversityRightImg {
        position: absolute;
        right: 0;
        top: 0px;
        width: 100%;
        max-width: 586px;
    }

    .aboutMenusContainer {
        overflow: auto;
    }

    .featureListbox_ .listHead_ {
        font-size: 11px;
    }

    .memberShipSpot .wavesubText {
        font-size: 18px;
    }
    .editProfileuser {
        max-height: 76px;
        max-width: 76px;
    }
    .membershipDetails .companyDetails {
        font-size: 13px;
    }
    .membershipDetails .companyprofileInfo {
        font-size: 14px;
    }
    
    .nav-link {
        padding: 8px 9px !important;
    }
    .authNavSearchBox{
        max-width: 150px;
    }
   
    .authIcons {
        width: 26px;
        height: 30px;
        object-fit: contain;
    }
    .notification img {
        width: 29px;
        height: 33px;
    }
    .userImg {
        width: 29px;
        height: 29px;
    }
}

@media (max-width:1200px) {
    .endorTabs .headingTitle_{
        font-size: 18px;
    }
    .nav-link{
        font-size: 15px;
    }
    .homeBanner {
        padding-bottom: 9rem;
    }

    .queryBox img {
        max-width: 580px;
    }

    .queryBox {
        padding: 4rem 40px;
    }

    .aboutContainer {
        padding-top: 10rem;
    }

    .innerSidebar .secondaryBtnGrey,
    .secondaryBtnOuter,
    .primaryBtnSmall {
        padding: 10px 23px !important;
        font-size: 12px;
    }

    .authMenus .userName {
        display: none !important;
    }

    .authMenus .userDetails {
        margin: 0 0px;
    }

    .authMenus .authItems {
        margin-right: 8px;
    }

    .customColLgThree {
        width: unset !important;
    }

    .customColLgFive {
        width: unset !important;
    }
    .newAccountDash .dashboardRightArea {
        display: none;
    }
    .whatPeopleSay .aboutusSubtext {
        font-size: 20px;
    }
    .headingBlue58{
        font-size: 48px;
    }
    .inputSearchForm input::placeholder{
        font-size: 13px;
    }
    .bussinessResourcePage .innerSubtext.txt20 br {
        display: none;
    }
    .bussinessResourcePage .innerSubtext.txt20 {
        font-size: 15px !important;
    }
    .bussinessResourcePage .premiumBtn{
        font-size: 15px;
    }
   
}

@media (max-width:1024px) {
    .navbar-brand img {
        width: 122px !important;
    }

    .queryBox {
        padding: 3rem 40px;
    }

    .queryBox img {
        max-width: 580px;
    }

    .queryBox .heading_Blue {
        font-size: 28px !important;
    }
    .nav-link {
        padding: 8px 8px !important;
    }
    .helpFullCon input::placeholder{
       font-size: 16px;
    }
    
    

}

@media (max-width: 991px) {
    .paymentScrolled {
        margin-top: 20px;
    }
    .inputSearchForm .inputClose {
        right: -4px;
        top: -2px;
    }
    .bottomMenu_ .searchBox {
        margin-bottom: 12px;
        margin-top: 36px!important;
    }
    .scrollnav {
        display: inline-block;
        height: 100%;
        overflow-y: scroll;
        width: 100%;
        max-height: 702px;
    }

    .membershipDetails .flexBox {
        display: flex;
    }
    .bussinessDevelopmentSection .heading_Blue {
        font-size: 25px;
    }

    .navbar-nav {
        width: 100%;
        background-color: #e7e7e7;
        padding: 0;
        min-width: 150px;
        position: absolute;
        align-items: self-start !important;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 15px !important;
        padding-left: 15px !important;
        color: #272727 !important;
    }

    .navheadInner .navbar-nav {
        padding: 0;
        top: 0;
        z-index: 9;
        height: calc(100vh - 70px);
    }
  
    .navbar {
        position: initial;
    }

    .aboutFounder {
        padding: 4rem 0;
    }

    .continue::before {
        width: 24%;
    }

    .continue::after {
        width: 24%;
    }

    .bannerHead {
        font-size: 30px;
    }

    .bannerTxt {
        font-size: 14px;
    }

    .secImg {
        height: 340px;
    }

    .userDetails {
        margin: 0;
    }

    .cardsVideo {
        margin-bottom: 20px;
    }

    .noPadding {
        padding-right: 0;
    }

    .faqImgbox {
        display: none;
    }

    .order-last {
        order: 0 !important;
    }

    .liveStreame {
        margin: 29px 0 65px;
    }

    .streamImg {
        width: 66%;
    }

    .contectUs_ .memberHomeImage_ {
        padding: 45px 30px;
    }

    .contactInfo_ {
        position: relative;
        margin-bottom: 40px;
    }

    .designLeft_ .boldmainHeading_ {
        font-size: 22px;
    }

    .containerBox {
        margin: 30px 0;
        height: auto;
        padding-right: 14px;
    }

    .react-tabs__tab-list {
        text-align: right;
    }

    .aboutLady {
        margin: 0 auto;
    }

    .homeBanner .btn-group {
        margin: 5rem 0 !important;
    }

    .videoBox {
        margin: 2rem 0;
    }

    .authItems .userName {
        overflow: hidden;
        white-space: nowrap;
        width: 60px;
        text-overflow: ellipsis;
    }

    .settingLinks {
        font-size: 14px;
    }

    .navbar-toggler {
        position: relative;
    }

    .navheadInner {
        position: absolute;
        right: 0;
        top: 63px;
        z-index: 4;
        width: 100%;
    }

    .bordeRight {
        margin-bottom: 20px;
    }

    .colContainer {
        margin-bottom: 20px;
    }

    .diversityRightImg {
        display: none;
    }

    .diversitySection {
        padding: 50px 0 50px 0;
    }

    .certificateFaq {
        padding: 50px 0 50px 0;
    }

    .queryBox .heading_Blue {
        font-size: 27px;
    }

    .queryBox {
        padding-bottom: 0;
    }

    .queryBox img {
        max-width: 100%;
    }

    .haveQuestion {
        position: static;
    }

    .homeBanner {
        padding-bottom: 1rem;
    }

    .contectUs_ .discription_ br {
        display: none;
    }

    .featureListbox_ {
        min-height: 532px;
    }

    .landingHead_ .bannerHead {
        font-size: 32px;
    }

    .loginDetails:before {
        opacity: 0;
    }

    .deactivateDeleteBtn .subsmallBtngreen_ {
        font-size: 12px;
    }

    .authNavBar .searchBox {
        display: none;
    }

    .navbar-brand img {
        width: 236px !important;
    }

    .mobileMenu_ {
        display: block !important;
        text-align: left;
        padding: 35px 30px;
        width: 100%;
        background: #fff;
    }

    .bottomMenu_ {
        padding: 30px 35px 40px;
        background: #e7e7e7;
        width: 100%;
    }

    .flexBox {
        display: none;
    }

    .mobileMenu {
        display: flex !important;
    }

    .profileBox_ .secondaryBtnOuter {
        width: 40%;
        display: inline-block;
        margin: 15px 0;
    }

    .bottomMenu_ .searchBox .searchBtn {
        position: absolute;
        right: 22px;
        top: 18px;
        left: auto;
    }

    .mobileMenu .navbar-toggler {
        padding-left: 0;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important;
    }

    .mobileMenu .nav-link {
        font-weight: 400;

    }

    .navbar-expand-lg .navbar-nav .bottomMenu_ .nav-link {
        color: #4a4a4a !important;
    }

    .homeBanner {
        padding: 80px 0;
    }

    .buyerService {
        padding: 100px 0;
    }

    .supplierService {
        padding: 100px 0;
    }

    .opportunites {
        padding: 100px 0;
    }

    .bussinessRelation {
        padding: 100px 0;
    }

    .sliderContainer {
        padding: 0 4%;
    }

    .slideContent {
        margin-right: 0;
    }

    .whatPeopleSay .aboutusSubtext {
        font-size: 22px;
    }

    .whatPeopleSay {
        padding: 50px 0;
    }

    .socialItems {
        margin-right: 11px;
    }

    .loginButton .nav-item a {
        color: rgba(0, 0, 0, .55) !important;
    }
    .business_search .inputSearchForm .input-group {
        display: block;
    }
    .beforeLogin{
        display: none;
    }
    .beforeMobileMenu .navbar-nav{
        background-color: #fff !important;
        top:18px;
        height: 100vh;
        overflow: auto;
    }
    .headingBlue58{
        font-size: 38px;
    }
    .txtBlack26 br{
        display: none;
    }
    .txtBlack26{
       font-size: 23px;
    }
    /* .beforeMobileMenu .container{
        padding: 0 20px;
    } */
    /* .limitedTimeOffer{
        position: relative;
        bottom: auto;
        font-size: 16px;
        margin-top: 10px;
    } */
    .bussinessImgs {
        border-radius: 20px 20px 0 0 !important;
    }
     .knowledgeInnerRight {
        padding: 27px !important;
        border-radius: 20px !important; 
    }
    .order-first_{
        order:1 !important;
    }
    
    .streamImg{
        width: 100%;
    }
    .order-second{
        order:1 ;
    }
    .spotlights .owl-nav, .mainSoundbites_ .owl-nav {
        position: absolute;
        top: -58px;
        right: 0;
    }
    .profileAbout {
        margin-top: 34px;
    }

}

@media (max-width: 767px) {
    .newPostOpportunity .checkmark_ label::before {
        top: 4px;
    }
    .activeOpportunity_ .innerSubtextSmall {
        line-height: 31px;
    }
    .activeOpportunity_ .postsDetails {
        padding: 0;
    }
    .PrivacySettings .subsmallBtnblack_ {
        font-size: 13px;
    }
    .privacySetting .headingTitleLight_ {
        font-size: 12px;
    }
    .continueField {
        font-size: 15px;
    }

    .authItems {
        margin-right: 10px;
    }

    .workDealing {
        padding: 0 0 0 0;
    }

    .faqcontentBox_ {
        padding: 15px 0 41px 0px;
    }

    .commonContentBox .aboutFounder {
        padding: 3em 0;
    }

    .streamImg {
        width: 100%;
    }

    .commonContentBox .aboutFounder .anotherHeading {
        font-size: 25px;
        padding-right: 0;
    }

    .designbottonContent_ {
        position: relative;

    }

    .designContent_ {
        margin: 16px 0 0;
    }

    /* .imgproduct_ {
        width: 32% !important;
    } */

    .designbottonContent_ {
        margin: 9px 0 0;
    }

    .designbtnRight {
        display: inline-block;
        margin: 11px 0 0;
    }

    .activeOpportunity_ .posts {
        display: block;
    }

    .recentlyDeleted .leftOpportunitycontent {
        width: 100%;
    }

    .activeOpportunity_ .postsDetails {
        width: 100%;
    }

    .opportunityBtn_ {
        width: 100%;
        text-align: center;
    }
    .activeOpportunity_ .opportunityBtn_ {
        text-align: left;
        width: 100%;
    }
    .activeOpportunity_ .leftOpportunitycontent {
        border-right: none;
    }
    .opportunityMain_ .subsmallBtnblue_ {
        width: 60%;
    }

    .opportunityMain_ .subsmallBtnblack_ {
        width: 100%;
    }

    .activeOpportunity_ .deleteDetails {
        margin-left: 0;
    }

    .submitopportunity {
        width: 90%;
        margin: 0 auto;
    }

    .footer {
        text-align: center;
    }

    .checkBox:checked+.checkBoxTxt:after {
        top: 11%;
    }

    .loginDetails:before {
        opacity: 0;
    }

    /* .companyDetailsLeft .designImg_ {
        width: 32%;
    } */

    .allConnection_ .postSec {
        margin: 29px 0;
    }

    .chnageProfile {
        right: 10px;
    }

    #editCompanyInformationDetails .modal-body {
        height: 100%;
        max-height: 500px;
        overflow: auto;
    }

    .stepOne,
    .stepTwo,
    .stepThree,
    .stepFour,
    .stepFive,
    .stepSix,
    .stepSeven {
        background-size: cover !important;
    }

    .bussinessRelLeftImg {
        display: block;
    }

    .whatPeopleSay {
        padding-bottom: 0;
    }

    .whatPeopleSay .owl-nav {
        position: static;
    }

    .whatPeopleSay .owl-carousel .owl-prev {
        position: static;
    }

    .whatPeopleSay .owl-carousel .owl-next {
        position: static;
    }

    .aboutUs .heading_Blue {
        font-size: 40px;
    }

    .bussinessResource .aboutContainer {
        padding-top: 6rem;
        padding-bottom: 2rem;
    }

    .bussinessResource .bannerHead {
        font-size: 51px;
    }

    .innerSidebar .checkBox:checked+.checkBoxTxt:after {
        top: 50%;
    }

    .profileResponsive {
        text-align: center;
    }

    .aboutprofile_ {
        text-align: center;
    }

    .about15 {
        text-align: center;
    }

    .missionBoard {
        padding: 4rem 0;
    }

    .newsPress {
        padding: 80px 0;
    }

    .mission {
        padding: 4rem 0 3rem;
    }
    .bussinessDevelopmentSection {
        padding: 80px 0;
    }
    .selectListbox {
        justify-content: start;
    }
    .selectListbox .radioCheck_ {
        margin-left: 0;
        margin-right: 20px !important;
    }
    .selectListbox .radioCheck_ {
        margin-left: 0;
        margin-right: 20px !important;
    }
    .activeOpportunity_ .opportunityBtn_ {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .opportunityMain_ .subsmallBtnblue_ {
        width: 40%;
    }
    .reviewResponse .responseBtn_ {
     
        max-width: 70px;
    }
    .reviewResponse .reviewContent_ {
        width: calc(100% - 77px);
    }
    .reviewResponse .subsmallBtnblue_ {
        width: 100%;
    }
    .manageUserTable td:nth-child(1) {
        min-width: 71px;
    }
    .manageUserTable td {
        min-width: 120px;
    }
    .headingBlue58 br{
        display: none;
    }
    .helpFullCon{
        margin-bottom: 10px;
    }
    .helpFullCon input{
        width: 71%;
    }
    .helpFullCon input::placeholder{
       font-size: 16px;
    }
    .helpFullCon button {
    
        font-size: 14px;
        color: #fff;
        padding: 11px 19px;
    }
    .opportunityComtent_:after{
        display: none;
    }
    
}

@media (max-width: 575px) {
    .inactiveOpportunities {
        padding: 9px 14px ;
        font-size: 12px;
    }
    .activeOpportunities {
        font-size: 18px;
    }
    .businessSearch .designcontentTop_, .opportunitiesPlans {
        flex-wrap: wrap;
    }
    .text-sm-center {
        text-align: center !important;
    }

    .streamImgchat {
        bottom: -38px;
        left: 0px;

    }

    /* .secImg,
    .goIcon,
    .goIconLeft {
        display: none !important;
    } */

    .whatPeopleSay .owl-nav {
        top: 100%;
    }

    .commonContentBox .aboutFounder .anotherHeading {
        line-height: 39px;
    }

    .rightNav_ {
        justify-content: right;
        margin: 0;
    }

    /* .lastImg .peopleImg {
        display: none;
    } */

    .outerIcon3 {
        right: 40px;
        top: -53px;
    }

    .verification .detailsContainer {
        padding: 4% 5%;
    }

    .whatPeopleSay .owl-carousel .owl-next {
        bottom: 12rem;
    }

    .whatPeopleSay .owl-carousel .owl-prev {
        left: -5px;
        bottom: 12rem;
    }

    .slideContent {
        margin-right: 0px;
        padding: 0 5px;
    }

    .rightNav_ {
        display: block;
    }

    .innerSidebar .searchBox {
        margin-bottom: 0;
    }

    .searchBox {
        margin: 10px auto;
    }

    .rightNav_ .flexBox {
        justify-content: end;
    }

    .outerIcon2 {
        position: absolute;
        right: 20px;
        top: -10px;
    }

    .listingMenusArea {
        display: block;
        margin-bottom: 15px;
    }

    .listingMenusArea .innerSubtext {
        width: 100%;
    }

    .listingItems {
        display: block;
        margin-bottom: 10px;
    }

    .whatPeopleSay .sliderContainer {
        padding: 0;
    }

    .queryBox .heading_Blue {
        font-size: 27px !important;
    }

    .containerBoard {
        flex-wrap: wrap;
    }

    .aboutContainer {
        padding-top: 5rem;
    }

    .aboutContainer .bannerHead {
        font-size: 46px;
    }

    .aboutContainer .bannerTxt {
        font-size: 21px;
    }

    .innerfawBox_ {
        padding: 40px 10px;
    }

    .selectListbox .secondaryBtnGrey {
        padding: 11px 18px !important;
    }

    .selectListbox .secondaryBtnGrey {
        padding: 11px 18px !important;
    }

    .mobileMenu_ {
        padding: 18px 10px 18px;
    }

    .bottomMenu_ {
        padding: 30px 10px;
    }

    .profileBox_ .secondaryBtnOuter {
        width: 100%;
    }

    .homePage .leftSec .heading_Blue {
        margin-bottom: 25px !important;
    }

    .homeBanner .btn-group {
        margin: 3rem 0 2rem !important;
    }

    .containerBoard {
        margin-bottom: 0;
    }

    .containerBoard .boardImg {
        margin-bottom: 15px;
    }

    .missionBoard .containerBoard .innerSubtextBold {
        font-size: 19px;
        line-height: 22px;
    }

    .growBusiness_ ul li {
        font-size: 15px;
    }

    .privacyHeading {
        margin: 15px 0 5px;
    }

    .frequentyQuestion_ .containerBox {
        margin: 0;
        padding: 0;
    }

    .frequentyQuestion_ .containerBox .memberHomeImage_ {
        margin-top: 0 !important;
    }
    .uploadbtn {
        font-size: 19px;
    }
    .uploadImgeboxNew .uploadText {
        font-size: 14px;
    }
    .PrivacySettings .listingItems {
        display: flex;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .designbtnRight {
        width: 100%;
    }
    .activeOpportunity_ .subsmallBtnblue_ {
        font-size: 13px!important;
        width: 51%;
    }
    .navbar-nav {
        padding: 15px 0;
    }
    .bottomMenu_ {
        padding: 10px 10px;
    }
    .navheadInner .nav-link {
        padding: 7px 14px !important;
    }
    .bottomMenu_ .searchBox {
        margin-top: 28px!important;
        margin-bottom: 50px;
    }
    .messageInboxcontent {
        display: inline-block;
        width: 100%;
        /* padding-left: 10px; */
    }
    .opportunityMain_ .containerBoxleft .subsmallBtnblue_ {
        width: 100%;
    }
    .freeTrialInner p{
        font-size: 14px;
    }
    .freeTrialLink{
        font-size: 14px;
    }
    .opportunityMsgHeight .containerBox{
        height: fit-content !important;
    }
    .opportunityMsgHeight .MessageInbox_ {
        height: fit-content !important;
    }
    .featureListbox_ {
        min-height: auto !important;
    }
    .newLeftBtns{
        flex-wrap: wrap;
        gap:10px;
    }
    .newCommonBtnOuter{
        max-width: fit-content;
    }
    .newProfileBtn{
        margin-left: 0;
    }
}


@media (max-width: 480px) {
    .startPostCon {
        padding: 13px 7px;
    }

    .postImg__ {
        width: 32px;
        height: 34px;
        margin-right: 0 !important;
    }

    .growBusiness_ {
        padding: 0 0;
        margin: 0 0 !important;
    }

    .termspara_ {
        font-size: 14px;
        text-align: justify;
    }

    .growMainbox_ {
        flex-wrap: wrap;
    }

    .memberHead_ {
        font-size: 24px;
    }

    .growbusinessRight_ {
        text-align: left;
        margin: 15px 0 0 72px;
    }

    .growImage img {
        width: 56px;
        margin-right: 15px;
    }

    .headingSmall_ {
        font-size: 14px;
    }

    .otherHeading_ {
        font-size: 28px;
    }

    .innerSubtext {
        font-size: 14px;
    }

    .faqcontentBox_ .row-title-text {
        font-size: 16px !important;
    }

    .contactHead_ {
        font-size: 40px;
    }

    .contectUs_ .memberHomeImage_ {
        padding: 28px 17px 45px;
    }

    .businessBtn .secondaryBtnGrey,
    .secondaryBtnOuter {
        padding: 12px 23px !important;
    }

    /* .imgproduct_ {
        width: 53% !important;
    } */

    .designcontentTop_ {

        align-items: center;
        flex-wrap: wrap;
    }

    .statusBtn_ {
        padding: 8px 13px;
    }

    .aboutCopany_ .subsmallBtnblue_ {
        padding: 10px 17px;
        font-size: 12px;
    }

    .viewProfile_ .headTextmain_ {
        font-size: 26px;
    }

    .businessBtn .primaryBtnSmall {
        padding: 12px 24px !important;
    }

    .modal-content {
        max-width: 355px;
        margin: 0 auto;
    }

    .infoiconText .showinfoText {
        width: 257px;
    }

    .navheadInner {
        right: 0;
        top: 70px;
    }

    /* .companyDetailsLeft .designImg_ {
        width: 49%;
    } */

    .queryBox .heading_Blue {
        font-size: 22px !important;
    }

    .homePage .heading_Blue br {
        display: none;
    }

    .landingHead_ .bannerHead br {
        display: none;
    }

    .queryBox {
        padding-left: 30px;
        padding-right: 30px;
    }

    .aboutLinks {
        font-size: 14px;
    }

    .aboutFounder .bannerHead {
        font-size: 31px;
    }

    .aboutFounder .bannerTxt {
        font-size: 14px;
    }

    .aboutUs .heading_Blue {
        font-size: 22px;
    }

    .registration .primaryBtn {
        font-size: 16px;
        padding: 9px 13px !important;
    }

    .selectListbox .radioCheck_ {
        margin-left: 4px;
        margin-right: 6px !important;
    }

    .topPagefilter .cateSelectbox {
        max-width: 118px;
    }

    .footerLinks {
        font-size: 13px;
    }

    .membershipDetails .paymentHeading,
    .historyPrice,
    .paymentSubheading {
        font-size: 17px;
    }

    .manageUserTable th {
        font-size: 14px;
    }

    .manageUser .primaryBtn {
        padding: 11px 13px !important;
        font-size: 11px !important;
    }

    .userAvailable>p {
        font-size: 14px;
    }

    .settings {
        margin: 0;
    }

    .deactivateDeleteBtn .secondaryBtnOuter {
        width: 100%;
    }

    .landingHead_ .bannerHead {
        font-size: 22px;
    }

    .landingHead_ .boldhead_ {
        line-height: normal;
        font-size: 18px;
    }

    .landingHead_ .boldhead_ b {
        line-height: normal;
    }

    .homeBanner .btn-group {
        margin: 2rem 0 !important;
    }

    .homePage .heading_Blue {
        font-size: 22px;
    }

    .homeRelevance .innerSubtext {
        font-size: 14px;
    }

    .buyerService {
        padding: 40px 0;
    }

    .outerBtn {
        padding: 7px 10px;
        font-size: 12px;
    }

    .supplierService,
    .opportunites {
        padding: 40px 0;
    }

    .opportunites .innerSubtext {
        font-size: 14px;
    }

    #community .innerSubtext {
        font-size: 14px;
    }

    .socialLinks img {
        width: 27px;
        height: 27px;
    }

    .whatPeopleSay {
        padding: 40px 0;
    }

    .mission .innerSubtext {
        font-size: 14px;
    }

    .aboutList {
        font-size: 18px;
    }

    .containerBoard .innerSubtext {
        font-size: 14px;
    }

    .newsPress .bannerHead {
        font-size: 40px;
    }

    .newsCard .innerSubtext {
        font-size: 14px;
    }

    .landingHead_ .heading_Blue {
        font-size: 28px !important;
    }

    .homeBanner.landingHead_ {
        padding: 70px 0;
    }

    .bussinessRelation {
        padding: 70px 0;
    }

    .goIconLeft,
    .goIcon {
        width: 50px;
    }

    .whatPeopleSay .aboutusSubtext {
        font-size: 17px;
        line-height: 26px;
    }

    .outerPages .heading_Blue {
        font-size: 36px;
    }

    .recomendedCard h3 {
        font-size: 20px;
    }
    .PrivacySettings .listingItems {
        display: block;
    }
    .PrivacySettings .subsmallBtnblack_ {
        text-align: left;
        margin-top: 10px;
    }
    .privacySetting .headingTitleLight_ {
        margin-bottom: 8px;
        margin-top: 5px;
    }
    .deactivateDeleteBtn .subsmallBtngreen_ {
        width: 100%;
    }
    .PrivacySettings .listingMenusArea {
        margin-bottom: 0;
    }
    .businessSearch .selectListbox {
        flex-wrap: wrap;
    }
    .businessSearch .selectListbox .radioCheck_ {
        margin-bottom: 8px;
    }
    .reviewResponse .posts {
       
        flex-wrap: wrap;
    }
    .reviewResponse .reviewContent_ { 
        width: calc(100% - 0px);
    }
    .reviewResponse .leftOpportunitycontent { 
        margin-right: 26px;
        padding-right: 5px;
    }
    .reviewResponse .newApplication { 
        padding-right: 6px;
    }
    .reviewResponse .responseBtn_ {
        justify-content: start!important;
        margin-top: 7px;
    }
    .reviewResponse .leftOpportunitycontent {
        width: 46%;
    }
    .activeOpportunity_ .leftOpportunitycontent {
        width: 100%;
    }
    .opportunityMsgHeight .subsmallBtnblue_ {
        width: 100%;
    }
    .navbar-brand img {
        width: 133px !important;
    }
    .owl-nav, .mainSoundbites_ .owl-nav {
        position: absolute;
        top: auto;
        /* right: 0; */
    }
    .bussinessImgs{
        border-radius:10px;
    }
    .knowledgeInnerRight{
        padding:20px 15px 25px 15px;
        border-radius: 10px;
    }
    .knowledgeExchangeReverse .bussinessImgs {
        border-radius: 10px;
    }
    .knowledgeExchangeReverse .knowledgeInnerRight{
        padding:20px 15px 25px 15px;
        border-radius: 10px;
    }
    .diversityRenewal img {
        margin-right: 16px;
    }
    .diversityLeft .diversityRenLeft {
        margin-right: 14px;
        padding-right: 14px;
    }
    .diversityRenLeft .txtBlue15{
        font-size: 10px;
    }
    .txt20{
        font-size: 16px;
    }
    .diversityRenRight .txtBlue15{
        font-size: 10px;
    }
    .supplierRight{
        padding-top: 20px;
    }
    .diveersityRight img {
        padding-top: 20px;
    }
    .diversityCon{
        padding-left: 30px;
    }
    .suggestionBox .primaryBtn{
        font-size: 15px;
    }
    .membershipBanner .memeberHead_{
        text-align: start !important;
    }
    .beforeMobileMenu .container{
        padding: 0;
    }
    .beforeMobileMenu .navbar-brand{
        padding-left: 10px;
    }
    .beforeMobileMenu .navbar-toggler{
        padding-left: 10px;
    }
    .headingBlue58{
        font-size: 31px;
    }
    .certificateBanner{
        height: calc(80vh - 233px);
    }
    .mobileAppRight{
        padding: 20px;
    }
    .mobileApp {
        padding-top: 60px;
    }
    .chooseMemberShip__{
        display: block;
        text-align: center;
    }
    .chooseMemberShip__ .enterPriceSub{
        text-align: center !important;
        margin-bottom: 15px !important;
    }
    .currentTop {
        flex-wrap: wrap;
        gap: 10px;
    }
    .activeOpportunities{
        font-size: 16px;
    }
    /* .viewAllBtn___{
        margin-left: auto;
    } */
    .inactiveOpportunities{
        margin-right: 0 !important;
        margin-left: auto;
    }
    .viewAllMobile{
        display: block;
        width: 100%;
    }
    .viewAllDesktop{
        display: none;
    }
    .appStore p{
        font-size: 9px;
    }
    .playStore p{
        font-size: 9px;
    }

.appStore h6 {
    font-size: 15px;
    line-height: 15px;
    }
    .playStore h6 {
        font-size: 15px;
        line-height: 15px;
    }
    .mbMobile{
        margin-bottom: 35px;
    }
    .mbMobile .rightImg{
        margin-top:0 !important;
    }
    .endorTabs .headingTitle_{
        font-size: 18px;
    }
    .endorTabs .react-tabs__tab {
        /* border: none !important; */
        font-size: 12px !important;
    }
    .endorTabs .react-tabs__tab {
        padding: 10px 16px !important;
    }
}
