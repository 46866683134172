.appStore {
    background-color: #000;
    display: flex;
    align-items: center;
    padding:5px 10px;
    border-radius: 10px;
}
.playStore {
    background-color: #000;
    display: flex;
    align-items: center;
    padding:5px 10px;
    border-radius: 10px;
}
.appImage {
    margin-right: 15px;
}
.playStore img{
    width: 28px;
    height: 33px;
}
.appStore p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
.appStore h6 {
    color: #fff;
    font-weight: 600;
    font-size: 21px;
    line-height: 13px;
    margin-bottom: 5px;
}
.playStore p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
.playStore h6 {
    color: #fff;
    font-weight: 600;
    font-size: 21px;
    line-height: 13px;
    margin-bottom: 5px;
}
.trustedText{
    /* background-color: #fff; */
    margin-bottom: 0;
    font-size: 22px;
    color:#000;
    /* padding:30px 0; */
    /* text-align: center; */

}


@media(max-width: 480px){
    .appStore p{
        font-size: 9px;
    }
    .playStore p{
        font-size: 9px;
    }

.appStore h6 {
    font-size: 15px;
    line-height: 15px;
    }
    .playStore h6 {
        font-size: 15px;
        line-height: 15px;
        }
}


